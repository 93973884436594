/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Box,
  Button,
  Alert,
  AlertTitle,
  Modal,
  Card,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  InputAdornment
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import phone from 'phone';
import { useEffect, useState } from 'react';
import {
  fetchUserProfile,
  createReview,
  getBreakdown,
  getPaymentUrl,
  makeKopoKopoPayment
} from '../../api';
import {
  capitalizeInitialLetter,
  capitalizeFirstLetter
} from '../../lib/utils';
import { Loader } from '../index';

function Index ({ invoice, jobDetail, quotations }) {
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState();
  const [open, setOpen] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  const [hover, setHover] = useState(-1);

  const [user, setUser] = useState([]);
  const [formValue, setFormValue] = useState();
  const [pageMessage, setPageMessage] = useState(false);
  const [paymentBreakdown, setPaymentBreakdown] = useState();
  const [paymentUrl, setPaymentUrl] = useState();
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [review, setReview] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [alternatePhone, setAlternatePhone] = useState(null);
  const [tableData, setTableData] = useState();

  const navigate = useNavigate();
  const [radioValue, setRadioValue] = useState();
  const url = window.location.href;
  localStorage.setItem('url', url);

  const style = {
    position: 'absolute',
    top: '50%',
    padding: 10,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

  const handleCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  const formatPrice = (amount) => {
    return `KES ${parseInt(amount).toFixed(2)}`;
  };

  const handleChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handlePhoneChange = (e) => {
    if (e.target.type === 'tel') {
      setAlternatePhone(phone(`+254-${e.target.value}`).phoneNumber);
    }
  };

  const handleTextChange = (event) => {
    setFormValue(event.target.value);
  };

  useEffect(() => {
    const alert = setTimeout(() => setPageMessage(), 6000);
    return () => {
      clearTimeout(alert);
    };
  }, [pageMessage]);

  const getUserProfile = async () => {
    setLoading(true);

    const response = await fetchUserProfile();

    if (response.error) {
      setLoading(false);
      return;
    }
    if (
      !response?.success &&
      response?.message?.toLowerCase().includes('unauthenticated.')
    ) {
      window.localStorage.clear();
      window.location.href = '/';
    }

    if (response?.success && response?.data?.user_type === 'customer') {
      setUser(response?.data);
      setLoading(false);
    }
  };

  const getRepaymentBreakdown = async () => {
    const response = await getBreakdown(invoice?.id);

    if (response?.error) {
      const { error } = response;
      setPageError(error?.message);
      return;
    }
    const { data } = response;
    localStorage.setItem('repaymentBreakdown', JSON.stringify(data));

    if (data.some((item) => item.status.toLowerCase() === 'pending')) {
      const filteredArray = data.filter(
        (item) => item.status.toLowerCase() === 'pending'
      );
      setPaymentBreakdown(filteredArray);
    }
  };

  useEffect(() => {
    if (!invoice?.payments) {
      getUserProfile();
    }
    if (invoice?.id) {
      getRepaymentBreakdown();
    }
    if (jobDetail) {
      const jobReview = jobDetail?.review;
      jobReview && setReview(true);
    }
  }, []);

  const makePaymentWithCellulant = async () => {
    setLoading(true);
    const response = await getPaymentUrl(radioValue);
    setLoading(false);
    if (!response || !response?.success) {
      setPageError(response?.message);
    }

    setPaymentModalOpen(false);
    setPageMessage(response?.message);
    setPaymentUrl(response?.data?.url);
  };

  const makePaymentWithMpesa = async () => {
    setLoading(true);
    const response = await makeKopoKopoPayment(radioValue, alternatePhone);
    setLoading(false);
    if (!response || !response?.success) {
      toast.error(response.message);
    }
    if (response?.success) {
      setPaymentModalOpen(false);
      toast.success('Your payment has been Successfully Completed');
      setTimeout(() => window.location.reload(), 12000);
    }
  };

  const handleMakeReview = async () => {
    setLoading(true);

    const data = {
      reviewer_id: invoice?.customer_id,
      user_id: invoice?.artisan_id,
      job_request_id: invoice?.job_request_id,
      rating: ratingValue,
      comment: formValue
    };
    const response = await createReview(data);

    if (response?.errors) {
      const { error } = response;
      toast.error(error);
    }

    if (response?.success) {
      const { message } = response;
      toast.success(message);
    }

    setTimeout(() => window.location.reload(), 2000);
  };

  const handleMakePayment = (e) => {
    setPaymentMethod(e.target.name);
    setPaymentModalOpen(true);
  };

  const handleMakePaymentMethod = () => {
    if (radioValue) {
      switch (paymentMethod) {
        case 'mpesa':
          makePaymentWithMpesa();
          break;
        case 'card':
          makePaymentWithCellulant();
          break;
        default:
          break;
      }
    } else {
      toast.error('Please select payment amount');
    }
  };

  useEffect(() => {
    if (paymentUrl) {
      window.open(paymentUrl);
    }
  }, [paymentUrl]);

  const setInvoice = () => {
    let data = {};

    if (quotations && Array.isArray(quotations) && quotations.length > 0) {
      const pendingQuotation = quotations.find(
        (quotation) => quotation.status === 'pending'
      );

      if (pendingQuotation) {
        data = pendingQuotation;
      }
    }

    if (Object.keys(data).length === 0 && Object.keys(invoice).length > 0) {
      data = invoice;
    }
    setTableData(data);
  };

  useEffect(() => {
    setInvoice();
  }, []);

  const itemsWithServiceFee = [];
  const itemsWithoutServiceFee = [];

  tableData?.items?.forEach((item) => {
    if (item?.item.includes('service fee')) {
      itemsWithServiceFee.push(item);
    } else {
      itemsWithoutServiceFee.push(item);
    }
  });

  // Calculate the sum of prices for items without a service fee
  const materialsTotal = itemsWithoutServiceFee.reduce(
    (total, item) => total + parseFloat(item.price), // Convert price to float
    0
  );

  return (
    <>
      {loading && <Loader />}
      <ToastContainer />
      {pageMessage && (
        <Alert severity="success">
          {' '}
          <AlertTitle>Sucess</AlertTitle>
          {pageMessage}
        </Alert>
      )}
      {pageError && (
        <Alert severity="error">
          {' '}
          <AlertTitle>Sucess</AlertTitle>
          {pageError}
        </Alert>
      )}
      <Grid
        maxWidth={{ sm: '90%', lg: '85%' }}
        marginX="auto"
        pl={{ lg: 2 }}
        alignItems="center"
        container
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="h4" component="h1">
            Job Invoice
          </Typography>
          <Typography>Ref: {tableData?.reference}</Typography>
          <Typography>
            Status:{' '}
            {tableData?.payments
              ? 'Paid'
              : capitalizeInitialLetter(tableData?.status)}
          </Typography>
          <Typography>
            {moment(tableData?.updated_at).format('MMM DD, YYYY')}
          </Typography>
          <Typography color="primary" fontWeight="bold">
            {formatPrice(tableData?.total_amount)}
          </Typography>
        </Box>
        <Box
          width={{ sm: '90%', lg: '70%' }}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'left',
            flexDirection: 'column'
          }}
        >
          <TableContainer sx={{ marginTop: '3rem' }}>
            <Table aria-label="quotation table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Item</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Quantity</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Unit Price</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemsWithoutServiceFee.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {capitalizeFirstLetter(item.item)}
                    </TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{formatPrice(Number(item?.price) / Number(item?.quantity))}</TableCell>
                    <TableCell>{formatPrice(item.price)}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Materials Total
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {formatPrice(materialsTotal)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {formatPrice(materialsTotal)}
                  </TableCell>
                </TableRow>

                {/* Display items with service fee */}
                {itemsWithServiceFee.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {capitalizeFirstLetter(item.item)}
                    </TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{formatPrice(item.price)}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {formatPrice(tableData?.total_amount)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {
            <Grid
              marginY={4}
              maxWidth="sm"
              marginX="auto"
              sx={{ display: 'flex', justifyContent: 'center' }}
              container
            >
              <Grid xs={12} md={4} mx={2} mt={2} lg={12} item>
                {paymentBreakdown?.length
                  ? (
                  <Grid container>
                    <Grid item mb={3} lg={12}>
                      <Button
                        name="mpesa"
                        variant="outlined"
                        size="large"
                        fullWidth
                        onClick={(e) => handleMakePayment(e)}
                      >
                        Pay With Mpesa
                      </Button>
                    </Grid>
                    <Grid item mb={3} lg={12}>
                      <Button
                        name="card"
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={(e) => handleMakePayment(e)}
                      >
                        Pay With Card
                      </Button>
                    </Grid>
                  </Grid>
                    )
                  : !review
                      ? (
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => setOpen(true)}
                  >
                    Leave a Review
                  </Button>
                        )
                      : (
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => setReviewModalOpen(true)}
                  >
                    View Review
                  </Button>
                        )}
              </Grid>
            </Grid>
          }
        </Box>
        <Typography>
          N.B: A fixed charge of{' '}
          <Typography component="span" color="primary">
            SH500
          </Typography>{' '}
          will be paid as{' '}
          <Typography component="span" color="primary">
            assessment fee
          </Typography>{' '}
          if quote is rejected after physical assessment
        </Typography>
      </Grid>
      <Modal
        open={reviewModalOpen}
        onClose={() => setReviewModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { lg: 600 }, padding: 'auto' }}>
          <Grid mb={1}>
            <Typography
              id="modal-modal-title"
              variant="h4"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              Review
            </Typography>
          </Grid>
          <Grid mb={4}>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="span"
            >
              You already left a review for this job....
            </Typography>
          </Grid>
          <Grid mb={4}>
            <Typography variant="subtitle1" id="simple-modal-description">
              You rated the service
            </Typography>
            <Rating
              mb={4}
              name="hover-feedback"
              value={jobDetail?.review?.rating}
              disabled
              precision={0.5}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            <Typography
              variant="subtitle1"
              id="simple-modal-description"
              mt={3}
            >
              You left the following comment
            </Typography>
            <TextField
              variant="outlined"
              id="comment"
              name="comment"
              value={jobDetail?.review?.comment}
              sx={{
                width: '300px'
              }}
              rows={2}
              disabled
              multiline
            />
          </Grid>
          <Grid>
            <Button
              type="submit"
              size="large"
              variant="contained"
              sx={{ width: 300 }}
              disabled
            >
              Submit
            </Button>
          </Grid>
        </Card>
      </Modal>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { lg: 600 }, padding: 'auto' }}>
          <Grid mb={1}>
            <Typography
              id="modal-modal-title"
              variant="h4"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              Your input is valuable to us
            </Typography>
          </Grid>
          <Grid mb={4}>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="span"
            >
              Would you mind taking a moment to rate your experience?
            </Typography>
          </Grid>
          <Grid mb={4}>
            <Typography variant="subtitle1" id="simple-modal-description">
              How was the service?
            </Typography>
            <Rating
              mb={4}
              name="hover-feedback"
              value={ratingValue}
              precision={0.5}
              onChange={(event, newValue) => {
                setRatingValue(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            <Typography variant="subtitle1" id="simple-modal-description">
              Do you want to leave a comment?
            </Typography>
            <TextField
              variant="outlined"
              id="comment"
              name="comment"
              onChange={(e) => handleTextChange(e)}
              sx={{
                width: '300px'
              }}
              rows={2}
              multiline
            />
          </Grid>
          <Grid>
            <Button
              type="submit"
              size="large"
              variant="contained"
              sx={{ width: 300 }}
              onClick={() => handleMakeReview()}
            >
              Submit
            </Button>
          </Grid>
        </Card>
      </Modal>
      <Modal
        open={paymentModalOpen}
        onClose={() => setPaymentModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { lg: 600 }, padding: 'auto' }}>
          <Grid container>
            <Grid mb={1} item lg={12}>
              <Typography
                id="modal-modal-title"
                variant="h4"
                fontWeight="bold"
                component="span"
                color="primary"
              >
                Please Select Amount Below
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography
                id="modal-modal-description"
                variant="h6"
                fontWeight="normal"
                component="span"
              >
                In other to continue with payment, please select an amount to
                pay
              </Typography>
            </Grid>
            <Grid m={4} sx={{ float: 'left' }}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={radioValue}
                  onChange={handleChange}
                >
                  {paymentBreakdown?.map((item) => (
                    <FormControlLabel
                      value={item.id}
                      control={<Radio />}
                      label={
                        formatPrice(item.amount) + ' - ' + item.description
                      }
                      key={item.id}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            {paymentMethod === 'mpesa' && (
              <Grid lg={12} m={3}>
                <FormControlLabel
                  label={
                    <Typography variant="p" component="p">
                      I&apos;`d like to use a different
                      <Typography color="primary" component="span" variant="p">
                        {''} phone number for Mpesa.
                      </Typography>
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckbox}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                ></FormControlLabel>

                {checked && (
                  <TextField
                    variant="standard"
                    name="phone"
                    label="Phone Number"
                    onChange={(e) => handlePhoneChange(e)}
                    type="tel"
                    fullWidth
                    inputProps={{ maxLength: 10 }}
                  />
                )}
              </Grid>
            )}
            <Grid mb={4} lg={12}>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  handleMakePaymentMethod();
                }}
              >
                Make Payment
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </>
  );
}

export default Index;
