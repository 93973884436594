export const capitalizeInitialLetter = (str) => {
  if (typeof str !== 'string') {
    return str;
  }

  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
  );
};

export const stringAvatar = (str) => {
  return {
    children: `${str.split(' ')[0][0].toUpperCase()}${str
      .split(' ')[1][0]
      .toUpperCase()}`
  };
};

export function capitalizeFirstLetter (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
