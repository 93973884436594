/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  TextField,
  Box,
  Typography,
  Tooltip,
  Alert,
  Divider,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ImageIcon from '@mui/icons-material/Image';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import { createJobRequest } from '../../api';

export default function Index ({ requestTypes, serviceType, locations }) {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [jobId, setJobId] = useState();
  const [schedule, setSchedule] = useState(
    moment().format('YYYY-MM-DD HH:mm:ss')
  );
  const [checked, setChecked] = useState(false);
  const [pageError, setPageError] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState([
    {
      service_type: serviceType,
      request_type_id: '',
      location_id: '',
      location_address: '',
      schedule,
      description: '',
      file: [],
      marked: checked
    }
  ]);

  const handleChange = (i, e) => {
    const newFormValues = [...formValues];
    const checkBox = 'marked';
    if (e.target.type === 'checkbox') {
      newFormValues[i][checkBox] = e.target.checked;
    }
    if (e.target.name === 'schedule') {
      newFormValues[i][e.target?.name] = moment(e.target.value).format(
        'YYYY-MM-DD HH:mm:ss'
      );
    } else {
      newFormValues[i][e.target?.name] =
        e.target.type === 'file' ? e.target.files[0] : e.target?.value;
      setFormValues(newFormValues);
    }
  };

  const handleCheckbox = (i, e) => {
    setChecked(e.target.checked);
    handleChange(i, e);
  };

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        service_type: serviceType,
        request_type_id: '',
        location_id: '',
        schedule,
        location_address: '',
        description: '',
        file: [],
        marked: checked
      }
    ]);
  };

  const removeFormFields = i => {
    const newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const removeFile = (index, element) => {
    const newFormValues = [...formValues];
    newFormValues[index].file = [];
    setFormValues(newFormValues);
  };

  const handleSubmit = event => {
    event.preventDefault();
    callCreateJobRequest(formValues);
  };

  const callCreateJobRequest = requests => {
    createJobRequest(requests).then(response => {
      setLoading(true);
      if (response?.success) {
        toast.success(
          `${response.data?.message}, You will be matched to an Artisan shortly`
        );

        setTimeout(
          () => navigate('/dashboard/job-requests', { replace: true }),
          6000
        );
      } else {
        setLoading(false);
        if (!response?.success) {
          setError(response?.exception_message);
          setPageError(true);
        }
      }
    });
  };

  useEffect(() => {
    const alert = setTimeout(() => setPageError(false), 3000);
    return () => {
      clearTimeout(alert);
    };
  }, [pageError]);

  return (
    <form onSubmit={handleSubmit}>
      <ToastContainer />
      {formValues.map((element, index) => (
        <Grid mt={2} columnSpacing={4} key={index} container>
          <Grid xs={12} mb={4} md={6} item>
            <Autocomplete
              id='select-request'
              name='request_type_id'
              sx={{ width: 300 }}
              options={requestTypes}
              autoHighlight
              getOptionLabel={option => option.name}
              onChange={(e, option) => {
                const clone = [...formValues];
                const obj = clone[index];
                obj.request_type_id = String(option.id);
                clone[index] = obj;
                setFormValues([...clone]);
              }}
              renderOption={(props, option) => (
                <Box component='li' {...props}>
                  {option.name}
                </Box>
              )}
              renderInput={params => (
                <TextField
                {...params}
                  variant='standard'
                  label='Job Request Type'
                  required
                  fullWidth
                  inputProps={{
                    ...params.inputProps
                    // autoComplete: 'new location', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          {/* <Grid xs={12} mb={4} sm={6} item>
            <TextField
              variant='standard'
              name='request_type_id'
              label='Job Request Type'
              value={element.request_type_id}
              onChange={e => handleChange(index, e)}
              select
              required
              fullWidth
            >
              {requestTypes.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          {/* <DatePicker
        label="Custom input"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <input ref={inputRef} {...inputProps} />
            {InputProps?.endAdornment}
          </Box>
        )}
      /> */}
          <Grid xs={12} mb={4} sm={6} item>
            <DatePicker
              label='Schedule'
              id='schedule'
              name='schedule'
              value={schedule}
              minDate={moment().toDate()}
              onChange={newValue => {
                setSchedule(() => newValue);
                handleChange(index, {
                  target: {
                    name: 'schedule',
                    value: moment(newValue).format('YYYY-MM-DD HH:mm:ss')
                  }
                });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='standard'
                  autoComplete='off'
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid xs={12} mb={4} md={6} item>
            <TextField
              variant='standard'
              name='location_address'
              label='Enter Your Address'
              value={element.location_address}
              onChange={e => handleChange(index, e)}
              required
              fullWidth
            ></TextField>
          </Grid>
          <Grid xs={12} mb={4} md={6} item>
            <Autocomplete
              id='select-location'
              name='location_id'
              sx={{ width: 300 }}
              options={locations}
              autoHighlight
              getOptionLabel={option => option.name}
              onChange={(e, option) => {
                const clone = [...formValues];
                const obj = clone[index];
                obj.location_id = String(option.id);
                clone[index] = obj;
                setFormValues([...clone]);
              }}
              renderOption={(props, option) => (
                <Box component='li' {...props}>
                  {option.name}
                </Box>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='standard'
                  label='Enter your location'
                  required
                  fullWidth
                  inputProps={{
                    ...params.inputProps
                    // autoComplete: 'new location', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12} mt={4} item>
            <TextField
              variant='outlined'
              id='description'
              name='description'
              label='Job Description'
              value={element.description}
              onChange={e => handleChange(index, e)}
              rows={2}
              required
              multiline
              fullWidth
            />
          </Grid>

          <Grid xs={12} mb={1} mt={4} md={6} item>
            <FormControlLabel
              label={<Typography>Mark As Urgent</Typography>}
              control={
                <Checkbox
                  checked={checked}
                  onChange={e => handleCheckbox(index, e)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            ></FormControlLabel>
          </Grid>
          {element.file.length !== 0
            ? (
            <Grid my={3} columnSpacing={6} px={4} container>
              <Grid xs={12} position='relative' item>
                <Tooltip title={element.file.name}>
                  <ImageIcon color='primary' sx={{ fontSize: '96px' }} />
                </Tooltip>
                <br />
                <Typography component='small'>
                  {element.file.name.substring(0, 14)}
                  {element.file.name.length > 14 && '...'}
                  <DeleteIcon
                    color='primary'
                    onClick={() => removeFile(index, element)}
                  />
                </Typography>
              </Grid>
            </Grid>
              )
            : (
            <Grid xs={12} mt={2} position='relative' item>
              <TextField
                type='file'
                id='file'
                sx={{ opacity: 0, zIndex: 100, cursor: 'pointer' }}
                name='file'
                onChange={e => handleChange(index, e)}
                fullWidth
              />
              <Box
                position='absolute'
                top={0}
                minHeight='100%'
                sx={{ cursor: 'pointer', display: 'flex' }}
                alignItems='center'
              >
                <CloudUploadOutlinedIcon />
                <Typography marginLeft={1}>
                  Upload Image / Video of Job
                </Typography>

                <Typography variant='body2' color='primary' size='8px' ml={1}>
                  (Note: format supported include png, pdf,jpeg, jpg)
                </Typography>
              </Box>
            </Grid>
              )}

          <Grid my={3} columnSpacing={6} px={4} container>
            <Grid xs={12} mb={2} sm={6} item sx={{ float: 'right' }}>
              {index
                ? (
                <Button
                  variant='text'
                  fontWeight='bold'
                  onClick={() => removeFormFields(index)}
                >
                  <DeleteIcon /> Remove Job Request
                </Button>
                  )
                : null}
            </Grid>
            <Grid mb={2} columnSpacing={6} px={4} container>
              <Grid xs={12} sm={6} item lg={12}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={10} marginX='auto'>
            {pageError && <Alert severity='error'>{pageError}</Alert>}
          </Grid>
          <Grid xs={10} marginX='auto'>
            {pageError && <Alert severity='error'>{error}</Alert>}
          </Grid>
        </Grid>
      ))}
      <Grid mt={1} xs={12} mb={2} sm={6} item>
        <Button
          variant='text'
          fontWeight='bold'
          onClick={() => addFormFields()}
        >
          <AddCircleIcon /> Add more job request
        </Button>
      </Grid>

      <Grid my={4} maxWidth={{ sm: '100%', lg: '50%' }} mx='auto'>
        <LoadingButton
          type='submit'
          size='large'
          variant={loading ? 'variant' : 'contained'}
          loading={loading}
          loadingIndicator='Submitting...'
          fullWidth
        >
          Place Request
        </LoadingButton>
      </Grid>
    </form>
  );
}
