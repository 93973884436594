import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
// import {
//   // Typography,
//   // Grid,
//   // Box
// } from '@mui/material';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import { useAuth } from '../context/useAuth';
import {
  Loader,
  NewArtisanDetails,
  MobileView,
  TabletView
} from '../components';
import { fetchQuotations, getInvoice, fetchJobRequest } from '../api';

// function TabPanel (props) {
//   // eslint-disable-next-line react/prop-types
//   const { children, value, index, ...other } = props;

//   return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
// }

function JobDetails () {
  const { user } = useAuth();
  // const [view, setView] = useState(0);
  const [loading, setLoading] = useState(false);
  const [jobDetail, setJobDetail] = useState({});
  const [quotations, setQuotations] = useState([]);
  const [invoice, setInvoice] = useState();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isTabletScreen = useMediaQuery(theme =>
    theme.breakpoints.between('sm', 'md')
  );

  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    fetchJobRequest(id).then(response => {
      if (response?.success) {
        const data = response?.data;
        setJobDetail(data);

        if (data?.invoice || data?.quotation) {
          fetchQuotations(id).then(response => {
            if (response?.success) {
              const filteredArray = response?.data.filter(
                item => item?.status === 'pending'
              );
              if (filteredArray.length) {
                setQuotations(filteredArray);
              } else {
                getInvoice(id).then(response => {
                  if (response.data) {
                    setInvoice(response.data);
                  }
                });
              }
            }
          });
        }
      }
    });
    setLoading(false);
  }, [id]);

  // const handleViewChange = (event, newView) => {
  //   if (newView !== null) {
  //     setView(newView);
  //   }
  // };

  return (
    <>
      {loading && <Loader />}
      {isSmallScreen
        ? (
        <MobileView
          user={user}
          jobDetail={jobDetail}
          invoice={invoice}
          quotations={quotations}
        />
          )
        : isTabletScreen
          ? (
        <TabletView user={user}
        jobDetail={jobDetail}
        invoice={invoice}
        quotations={quotations}/>
            )
          : (
        <NewArtisanDetails
          user={user}
          jobDetail={jobDetail}
          invoice={invoice}
          quotations={quotations}
        />
            )}
    </>
  );
}

export default JobDetails;
