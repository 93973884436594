/* eslint-disable react/no-unescaped-entities */
import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  ToggleButtonGroup,
  Button,
  ToggleButton,
  Grid,
  Modal,
  Card,
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import validator from 'validator';
import phone from 'phone';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import SignUpForm from './SignUpForm';
import SignInForm from './SignInForm';
import Logo from '../Logo/Logo';
import GoogleIcon from '../../assets/icons/google.png';
import { socialLogin } from '../../api';
import { useAuth } from '../../context/useAuth';
import Loader from '../Loader';

function AuthForm () {
  const [authForm, setAuthForm] = useState('Sign Up');
  const [user, setUser] = useState();
  const [userToken, setUserToken] = useState();
  const [profile, setProfile] = useState();
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const handleCheckbox = event => {
    setChecked(event.target.checked);
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const style = {
    position: 'absolute',
    top: '50%',
    padding: 10,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

  const login = useGoogleLogin({
    onSuccess: codeResponse => setUser(codeResponse),
    onError: error => toast.error('Login Failed:', error)
  });

  const handleChange = (event, newAuthForm) => {
    if (newAuthForm !== null) {
      setAuthForm(newAuthForm);
    }
  };

  const checkPage = data => {
    if (authForm === 'Sign Up') {
      setPopup(true);
    } else {
      socialLoginCallback(data);
    }
  };

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          }
        )
        .then(res => {
          setProfile(res?.data);
          setUserToken(user?.access_token);
          checkPage(res?.data);
        })
        .catch(err => console.log(err));
    }
  }, [user]);

  const onSubmit = data => {
    setLoading(true);
    if (data) {
      socialLoginCallback(data);
    }
  };

  const socialLoginCallback = async data => {
    setLoading(true);
    const response = await socialLogin({
      ...data,
      phone: phone(`+254-${data.phone}`).phoneNumber,
      user_type: 'customer',
      first_name: profile?.given_name || data?.given_name,
      last_name: profile?.family_name || data?.family_name,
      provider_id: profile?.id || data?.id,
      email: profile?.email || data?.email,
      access_token: userToken || user?.access_token
    });

    setLoading(false);

    if (!response?.success && response?.error) {
      toast.error(response?.error?.message);
    }

    if (response.errors?.password) {
      const { password } = response.errors;
      toast.error(password[0]);
      return;
    }
    if (response?.success) {
      const { token, user } = response.data;
      localStorage.setItem('token', JSON.stringify(token.access_token));
      localStorage.setItem('user', JSON.stringify(user));
      if (user && user?.phone_verified) {
        auth.login(user);
        navigate('/dashboard', { replace: true });
      } else {
        navigate('/verification', { replace: true });
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <ToastContainer />
      <Logo />
      <Grid maxWidth='1200px' sx={{ margin: 'auto' }} container>
        <Grid sm={8} md={5} lg={6} item>
          {authForm === 'Sign Up' && (
            <Typography
              variant='h4'
              component='h1'
              mt={8}
              mb={4}
              maxWidth='60%'
            >
              Hire{' '}
              <Typography
                variant='h4'
                fontWeight='bold'
                component='span'
                color='primary'
              >
                Craft
              </Typography>{' '}
              and{' '}
              <Typography
                variant='h4'
                fontWeight='bold'
                component='span'
                color='primary'
              >
                Vocational
              </Typography>{' '}
              Artisans
            </Typography>
          )}
          {authForm === 'Sign In' && (
            <>
              <Typography
                variant='h3'
                component='h1'
                mt={8}
                mb={4}
                maxWidth='95%'
                lineHeight='1'
                fontWeight='bold'
              >
                Welcome Back! <br />
                <Typography variant='h5' component='span'>
                  We have missed you!
                </Typography>
              </Typography>
            </>
          )}
        </Grid>
        <Grid
          xs={12}
          sm={10}
          md={7}
          lg={6}
          marginTop={2}
          item
          sx={{ backgroundColor: 'white' }}
        >
          <Box
            px={3}
            py={4}
            boxShadow='8px 8px 16px 4px rgba(0,0,0,0.15)'
            borderRadius={3}
          >
            <ToggleButtonGroup
              color='primary'
              value={authForm}
              onChange={handleChange}
              exclusive
            >
              <ToggleButton value='Sign In'>Sign In</ToggleButton>
              <ToggleButton value='Sign Up'>Sign Up</ToggleButton>
            </ToggleButtonGroup>
            {authForm === 'Sign Up' && <SignUpForm />}
            {authForm === 'Sign In' && <SignInForm />}
            <Grid mb={3}>
            <Button
                  size='large'
                  variant='outlined'
                  fullWidth
                  disabled
                  style={{ display: 'none' }}
                  onClick={() => login()}
                >
                  <Grid component='span' mr={2}>
                    <img src={GoogleIcon} alt='Google Icon' />
                  </Grid>
                  <Grid component='span'>Continue with Google</Grid>
                </Button>
            </Grid>
            {authForm === 'Sign Up'
              ? (
              <Typography mt={2}>
                Have an Account?{' '}
                <Button
                  component='span'
                  variant='text'
                  color='primary'
                  onClick={event => handleChange(event, 'Sign In')}
                  sx={{ padding: 0 }}
                >
                  Sign In
                </Button>
              </Typography>
                )
              : (
              <Typography mt={2}>
                Don't have an Account?{' '}
                <Button
                  component='span'
                  variant='text'
                  color='primary'
                  onClick={event => handleChange(event, 'Sign Up')}
                  sx={{ padding: 0 }}
                >
                  Sign Up
                </Button>
              </Typography>
                )}
          </Box>
        </Grid>
        {popup && authForm === 'Sign Up' && profile && (
          <Modal
            open={popup}
            onClose={() => setPopup(false)}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Card sx={{ ...style }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid xs={12} m={3} lg={6} item>
                  <TextField
                    type='tel'
                    label='Phone Number'
                    variant='standard'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>+254</InputAdornment>
                      )
                    }}
                    {...register('phone', {
                      required: {
                        value: true,
                        message: 'Phone number is required'
                      },
                      maxLength: {
                        value: 10,
                        message: 'Phone number cannot exceed 10 digits'
                      },
                      validate: value =>
                        validator.isMobilePhone(value) &&
                        phone(`+254-${value}`).isValid
                    })}
                    required={!!errors.username}
                    fullWidth
                  />
                   <Typography variant='small' component='small' color='error'>
              {errors.phone?.message}
              {errors.phone &&
                errors.phone.type === 'validate' &&
                'Enter a valid phone number'}
            </Typography>
                </Grid>
                <Grid mb={3} mt={5} width='100%'>
                  <FormControlLabel
                    label={
                      <Typography variant='p' component='span'>
                        I agree with the{' '}
                        <Link
                          to='/terms-and-conditions'
                          target='_blank'
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography
                            color='primary'
                            component='span'
                            variant='p'
                          >
                            Terms and Conditions
                          </Typography>
                        </Link>
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckbox}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                  ></FormControlLabel>
                </Grid>
                <Grid mb={3}>
                  <Button
                    size='large'
                    variant={'contained'}
                    fullWidth
                    type='submit'
                    disabled={!checked}
                    loadingIndicator='Loading...'
                  >
                    Continue
                  </Button>
                </Grid>
              </form>
            </Card>
          </Modal>
        )}
      </Grid>
    </>
  );
}

export default AuthForm;
